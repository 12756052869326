<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
      <v-row>
        <v-col>
          <base-tab-panel
              :module_name="page.name"
              :record="record"
              @change="save()"
              @archive="archive()"
              :loading="loading"
              :model="page.model">
            <template v-slot:main_fields>
              <template v-for="field in usual_fields">
                <base-field
                    :isDisabled="!canEdit(record.id || 0)"
                    :key="field.name"
                    :value="record[field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
              <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
            </template>
            <template v-if="custom_fields.length > 0" v-slot:module_fields>
              <template v-if="record.custom_fields">
                <template v-for="field in custom_fields">
                  <base-field
                      :isDisabled="!canEdit(record.id || 0)"
                      :key="field.name"
                      :value="record.custom_fields[field.name]"
                      :field="field"
                      @change="customFieldDataChanged"/>
                </template>
              </template>
            </template>
          </base-tab-panel>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="panel" multiple>
            <base-table-includable ref="forms_table" :page="page" :parent_module_id="record.id" relation_name="forms" @change="load" @create="createForm" @edit="editForm"/>
          </v-expansion-panels>
        </v-col>
      </v-row>
    <create-form modal_name="create_form" ref="create_form" @refresh="load()" :model_id="record.id"/>
    </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import CreateForm from "../forms/Create";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    BaseTabPanel,
    BaseTableIncludable,
    CreateForm,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("equipments"),
        name: "equipment",
        model: "equipment",
      },
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.forms_table.records = this.record.forms;
      this.$refs.forms_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createForm() {
      this.$refs.create_form.load();
    },
    editForm(item) {
      this.$router.push("/forms/" + item.id);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>